import React from 'react';

import {
  Col,
  Card
} from "react-bootstrap";
const ExperienceCard = ({ data }) => {
  return (
    <Col lg="12">
      <div className="pb-5 text-center">
        <img
          className=" bg-white mb-3"
          src={data.companylogo} alt=""
          width={data.imgWidth}
          height={data.imgHeight}
        />
        <p className="lead">
          {data.role}
          <br />
          {data.date}
          <br />
        </p>
          <h4 className="display-5 mb-2 text-center">Descrição</h4>
        <p className='lead'>
          {data.desc}
        </p>

      </div>
    </Col>
  );
}

export default ExperienceCard;
