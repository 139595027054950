// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#002900, #035d0c, #2b9822, #114211, #0d2a0c, #49ff31",
  firstName: "Matheus",
  middleName: "",
  lastName: "Motta",
  message: "Cyber Security Analyst | Pentester",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/Mathysic-s/",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/matheusrmotta/",
    },
    {
      image: "fa fa-envelope",
      url: "mailto:contato@mrmotta.com.br",
      profilePictureLink:"",
    },
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/matheusrmotta/",
    }
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "Sobre",
  imageLink: require('../assets/img/perfil 3D.png'),
  imageSize: 375,
  message: `Olá, meu nome é Matheus Motta, tenho 23 anos, sou um profissional de cibersegurança formado em Análise e Desenvolvimento de Sistemas,
   e pós-graduação em Ethical Hacking e Cibersecurity, mais de 6 anos de experiência com TI, sendo 4 anos como auxilia de informática no 
   Exército Brasileiro, atualmente alocado no SESC Minas, terceirizado pela CyberOne como analista de segurança da informação, 
   empresa com mais de 15 anos no mercado de segurança. Tenho experiência com monitoramento e resposta a incidentes, background 
   na utilização de inúmeras ferramentas, tais como Kaspersky XDR, Cortex XDR, Lumu, QRadar, Nessus, e firewalls como Palo Alto e
    PFSense. Expertise na administração de sistemas Linux e Windows, e automação de tarefas por meio de scripts em Python, 
    Bash Script e PowerShell. Além disso, já desempenhei o papel de palestrante, conscientizando sobre as melhores práticas 
    de Segurança da Informação no âmbito militar.
  `,
  resume: require('../assets/resumo.pdf'),
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Projetos",
  gitHubUsername: "Mathysic-s", //i.e."johnDoe12Gh"
  reposLength: 0,
  specificRepos: ["SOCTools","Crypt3r", "dsimplesqlitest", "ddos-by-mathysics"],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    {
      img: "",
      label: "First slide label",
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum."
    },
  ],
  imageSize: {
    width: "615",
    height: "450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Habilidades",
  hardSkills: [
    { name: "Web Hacking", value: 77 },
    { name: "Information Gathering", value: 81 },
    { name: "Vulnerability Analysis", value: 85 },
    { name: "Exploitation", value: 73 },
    { name: "Pos-Explotation", value: 68 },
    { name: "Social Engineering", value: 85 },
    { name: "Report Generation", value: 75 },
    { name: "Scanning", value: 97 },
    { name: "Reverse Engineeringlog analysis", value: 67 },
    { name: "Log Analysis", value: 88 },
  ],
  softSkills: [
    { name: "Proatividade", value: 86 },
    { name: "Colaboração", value: 78 },
    { name: "Liderança", value: 89 },
    { name: "Resiliência", value: 85 },
    { name: "Resolução de Problemas", value: 95 },
    { name: "Empatia", value: 90 },
    { name: "Organização", value: 70 },
    { name: "Criatividade", value: 80 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Entre em contato",
  message:
    "Se precisar de ajuda em um projeto, ou talvez tenha algo interessante a dizer, entre em contato comigo em",
  email: "contato@mrmotta.com.br",
};

const experiences = {
  show: true,
  heading: "Experiência",
  data: [
    {
      role: 'Cyber Security Analyst - CyberOne',// Here Add Company Name
      companylogo: require('../assets/img/Logo Cyber - JPEG.jpg'),
      imgWidth: 175,
      imgHeight: 110,
      date: 'Março 2024 – Presente',
      desc: "Como Cyber Security Analyst na CyberOne, sou responsável por administrar os firewalls Palo Alto nas mais de 30 filiais localizadas em Minas Gerais, garantindo a segurança e proteção dos sistemas e dados sensíveis do nosso cliente, SESC Minas. Trabalhando como parte de uma equipe dedicada, emprego uma variedade de ferramentas e técnicas como Cortex XDR, Lumu, Nessus e QRadar  para identificar e mitigar ameaças cibernéticas e vulnerabilidades de segurança.",
    },
    {
      role: 'Auxiliar de Informática - Exército Brasileiro',// Here Add Company Name
      companylogo: require('../assets/img/eb.png'),
      imgWeidth: 170,
      imgHeight: 210,
      date: 'Março 2020 – Fevereiro 2024',
      desc: "Lotado na seção de informática , responsável pela administração e segurança dos servidores locais como de intranet, e-mail, sistema de almox etc , assim como os testes de vulnerabilidades nos mesmos. Responsável também por manter e realizar todas modificações no site da organização militar padrão gov.br (Joomla) e pela administração do PFSense com ACLs e análise do tráfego de rede e logs a procura de possíveis ameaças. Criação de diversos scripts (bash e Python) com intuito de agilizar tarefas, administração do ActiveDirectory (Criação e modificação de grupos e usuários), e incumbido de ministrar diversas palestras sobre segurança da informação com base ISO 27001 para os militares desta organização.",
    },
  ]
}

const educations = {
  show: true,
  heading: "Educação e Certificados",
  data: [
    {
      tipo: 'Graduação',
      role: 'Análise e Desenvolvimento de Sistemas',// Here Add Company Name
      companylogo: require('../assets/img/estacio-logo.png'),
      imgSize: 170,
      date: 'Março 2021 – Setembro 2023',
    },
    {
      tipo: 'Curso',
      role: 'Pentest Profissional - Desec Security',// Here Add Company Name
      companylogo: require('../assets/img/desec.png'),
      imgSize: 170,
      date: 'Março 2018',
      desc: 'BTFF-FXFAG-LLFT',
      cert: "https://desecsecurity.com/valida-certificado/BTFF-FXFAG-LLFT",
    },
    {
      tipo: 'Curso',
      role: 'Endpoint Security',// Here Add Company Name
      companylogo: require('../assets/img/cisco.png'),
      imgSize: 170,
      date: 'Maio 2023',
      desc: 'a097ebf1-d9b0-4fa4-a9b2-fd54cd711d0d',
      cert: "https://www.credly.com/badges/a097ebf1-d9b0-4fa4-a9b2-fd54cd711d0d?source=linked_in_profile",
    },
    {
      tipo: 'Curso',
      role: 'Cybersecurity Essentials',// Here Add Company Name
      companylogo: require('../assets/img/cisco.png'),
      imgSize: 170,
      date: 'Fevereiro 2022',
      desc: '8cdfb35c-e42d-4a2a-925f-cf00b1e37999',
      cert: "https://www.credly.com/badges/8cdfb35c-e42d-4a2a-925f-cf00b1e37999?source=linked_in_profile",
    },
    {
      tipo: 'Curso',
      role: 'Introduction to Cybersecurity',// Here Add Company Name
      companylogo: require('../assets/img/cisco.png'),
      imgSize: 170,
      date: 'Fevereiro 2022',
      desc: 'd3b96a94-0e2d-4b79-870f-34b85fb6c3a1',
      cert: "https://www.credly.com/badges/d3b96a94-0e2d-4b79-870f-34b85fb6c3a1?source=linked_in_profile",
    },
    {
      tipo: 'Curso',
      role: 'Fundamentos de segurança cibernética',// Here Add Company Name
      companylogo: require('../assets/img/ibm.png'),
      imgSize: 170,
      date: 'Março 2023',
      desc: '9dd4f9c5-2877-42ae-9e3e-9782a08c03ca',
      cert: "https://www.credly.com/badges/9dd4f9c5-2877-42ae-9e3e-9782a08c03ca/public_url",
    },
    {
      tipo: 'Certificação',
      role: 'Network Security Expert (NSE) 3',// Here Add Company Name
      companylogo: require('../assets/img/fortinet.png'),
      imgSize: 170,
      date: 'Abril 2023',
      desc: 'NXj3awWr06',
      cert: "https://training.fortinet.com/mod/customcert/verify_certificate.php?contextid=251441&code=NXj3awWr06&qrcode=1",
    },
    {
      tipo: 'Certificação',
      role: 'Network Security Expert (NSE) 2',// Here Add Company Name
      companylogo: require('../assets/img/fortinet.png'),
      imgSize: 170,
      date: 'Abril 2023',
      desc: 'x30X3ycl0d',
      cert: "https://training.fortinet.com/mod/customcert/verify_certificate.php?contextid=251440&code=x30X3ycl0d&qrcode=1",
    },
    {
      tipo: 'Certificação',
      role: 'Network Security Expert (NSE) 1',// Here Add Company Name
      companylogo: require('../assets/img/fortinet.png'),
      imgSize: 170,
      date: 'Abril 2023',
      desc: '2yF2asLbIY',
      cert: "https://training.fortinet.com/mod/customcert/verify_certificate.php?contextid=251439&code=2yF2asLbIY&qrcode=1",
    },
    {
      tipo: 'Curso',
      role: 'Python',// Here Add Company Name
      companylogo: require('../assets/img/fiap.png'),
      imgSize: 170,
      date: 'Maio 2023',
      desc: '9cf07cb8a90b484afc6f225303f4d091',
      cert: "https://on.fiap.com.br/pluginfile.php/1/local_nanocourses/certificado_nanocourse/84428/9cf07cb8a90b484afc6f225303f4d091/certificado.png",
    },
    {
      tipo: 'Curso',
      role: 'Ethical Hacking Essentials (EHE)',// Here Add Company Name
      companylogo: require('../assets/img/ec-council.png'),
      imgSize: 170,
      date: 'Maio 2023',
      desc: '377b1f14-1f32-42c7-8d11-f1f03631e623',
      cert: "https://codered.eccouncil.org/certificate/377b1f14-1f32-42c7-8d11-f1f03631e623",
    },
    {
      tipo: 'Certificação',
      role: 'Python para Cibersegurança Associado',// Here Add Company Name
      companylogo: require('../assets/img/ibsec.png'),
      imgSize: 170,
      date: 'Maio 2023',
      desc: 'db698386acdb158d',
      cert: "https://certs.ibsec.com.br?cert_hash=db698386acdb158d",
    },
    {
      tipo: 'Certificação',
      role: 'ISO/IEC 27001 Information Security Associate',// Here Add Company Name
      companylogo: require('../assets/img/skillfront.png'),
      imgSize: 170,
      date: 'Maio 2023',
      desc: '83687770192329',
      cert: "https://www.skillfront.com/Badges/83687770192329",
    },
    {
      tipo: 'Certificação',
      role: 'Fundamentos Na Lei Geral De Proteção De Dados - LGPDF™',// Here Add Company Name
      companylogo: require('../assets/img/certiprof.png'),
      imgSize: 170,
      date: 'Maio 2023',
      desc: 'c3b39513-c1b6-4ed1-bf4d-9aaa3722c303',
      cert: "https://www.credly.com/badges/c3b39513-c1b6-4ed1-bf4d-9aaa3722c303/linked_in_profile",
    },
    
  ]
  
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences, educations };
