import React from 'react';

import {
  Col,
  Card
} from "react-bootstrap";
const EducationCard = ({ data }) => {
  return (
    <Col lg="6">
      <div className="pb-5 text-center">
      <a id='certificados' target="_blank" href={data.cert}>
        <img
          className=" bg-white mb-3"
          src={data.companylogo} alt=""
          width={data.imgSize}
          height={data.imgSize}
        />
        <p className="lead">
          {data.tipo}
          <br />
          {data.role}
          <br />
          {data.date}
          <br />
          {data.desc}
        </p></a>

      </div>
    </Col>
  );
}

export default EducationCard;
